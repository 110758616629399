@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'monstrat'; 
    src: url('../../10lps/public/Montserrat-ExtraBold.ttf') format('truetype'); 
    font-weight: bold; 
    font-style: normal;
}